<template>
	<div id="storeDetail" class="el-content">
		<a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
			<a-form-item label="会员id">
				<com-select-user :value="form.account_id" @change="e=>form.account_id = e "></com-select-user>
			</a-form-item>
			<a-form-item label="商户名称" required>
				<a-input v-model:value="form.name"></a-input>
			</a-form-item>
			<a-form-item label="手机号" required>
				<a-input v-model:value="form.mobile"></a-input>
			</a-form-item>
			<a-form-item label="商户LOGO">
				<kd-img-select :src="form.logo" @success="(url)=>{ form.logo = url }"></kd-img-select>
			</a-form-item>
			<a-form-item label="店铺地址" required>
				<kd-map :value="form.longitude+','+form.latitude" @change="getAddress"></kd-map>
			</a-form-item>
			<a-form-item label="店铺介绍">
				<a-textarea v-model:value="form.description" placeholder="店铺介绍" :rows="4" />
			</a-form-item>
			<a-form-item label="是否展示手机号">
				<a-radio-group v-model:value="form.showmobile">
					<a-radio :value="1">展示</a-radio>
					<a-radio :value="2">不展示</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="店铺状态">
				<a-radio-group v-model:value="form.lib_status">
					<a-radio :value="1">启用</a-radio>
					<a-radio :value="2">禁用</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="店铺备注">
				<a-textarea v-model:value="form.note" placeholder="店铺备注" :rows="4" />
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 3 }">
				<a-space>
					<a-button type="primary" @click="submitSave">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import comSelectUser from '@/components/miniapp/com-select-user.vue'
import { reactive, toRefs } from 'vue'
import shopModel from '@/api/addons/shop'
export default{
	components:{
		comSelectUser
	},
	setup(){
		const _d = reactive({
			form:{
				name:'',
				logo:'',
				card_img:'',
				mobile:'',
				business_img:'',
				lib_status:1,
				note:'',
				address:'',
				latitude:'',
				longitude:'',
				account_id:0,
				description:'',
				showmobile:1
			}
		})
		function getAddress(e){
			_d.form.latitude = e.latitude
			_d.form.longitude = e.longitude
			_d.form.address = e.address
		}
		const submitSave = ()=>shopModel.addOrEditShop(_d.form)

		return{
			...toRefs(_d),
			getAddress,
			submitSave
		}
	}
}
</script>

<style>
</style>
